<template>
  <div class="user-my-consult" v-wechat-title="'我的咨询'">
    <div class="user-my-consult-tag">
      <span v-for="el in consultTag" :key="el.id" :class="{active:consultTagId == el.id}" @click="consultTagId = el.id">{{el.title}}</span>
    </div>
    <van-list
      v-if="consultList != ''"
      v-model="loading"
      :finished="finished"
      finished-text="暂无更多数据"
      @load="onLoad"
    >
      <div class="user-my-consult-list">
        <div v-for="(el,index) in consultList" :key="index">
          <span :class="{'1':'sqz','2':'ysl','3':'yhf','4':''}[el.consultStatus]">{{ el.consultStatus | dict('CONSULT_STATUS') }}</span>
          <strong>{{ el.consultTitle }}</strong>
          <div class="user-my-consult-time-box">
            <p>
              <span class="user-my-consult-time-title"><img src="@/assets/img/time.png" alt="图片"> 提交时间</span>
              <span class="user-my-consult-time">{{ el.consultTime }}</span>
            </p>
            <p v-if="el.consultStatus == '3'">
              <span class="user-my-consult-time-title"><img src="@/assets/img/time.png" alt="图片"> 回复时间</span>
              <span class="user-my-consult-time">{{ el.replyTime }}</span>
            </p>
          </div>
          <div class="user-my-consult-info" @click="$router.push(`/myConsult/info?id=${el.id}`)">
            <span>查看详情</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </van-list>
    <van-empty
      v-if="isEmpty"
      class="empty-image"
      :image="require('@/assets/img/4886@2x.png')"
      description="暂无更多数据"
    />
  </div>
</template>

<script>
import { getConsultList } from '@/api/consult'

export default {
  data () {
    return {
      page: {
        current: 1,
        size: 10,
        pageSize: 1
      },
      loading: false,
      finished: false,
      consultList: [],
      consultTagId:'',
      consultTag:[{
        title:'全部',
        id:''
      },{
        title:'待处理',
        id:'1'
      },{
        title:'已受理',
        id:'2'
      },{
        title:'已回复',
        id:'3'
      },{
        title:'已关闭',
        id:'4'
      }],
      isEmpty:false
    }
  },
  watch:{
    consultTagId:function(e){
      this.consultList = []
      this.finished = false
      this.page.current = 1
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    onLoad () {
      if (!this.consultList || !this.consultList.length) {
        this.finished = true;
        return
      }
      if (this.page.pageSize === this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadData()
    },
    // 解决方案列表
    loadData () {
      const { page,consultTagId } = this
      const data = {
        consultStatus:consultTagId
      }
      getConsultList(page, data).then((res) => {
        this.loading = false
        this.consultList = [...this.consultList, ...res.records]
        this.page.pageSize = res.pages
        this.finished = false
        if(this.consultList == ''){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-my-consult{
  min-height: calc(100vh - 56px);
  background: #F6F6F6;
  .user-my-consult-tag{
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    >span{
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      color: #000000;
      position: relative;
      &.active{
        color: #EA0B06;
        &::after{
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 3px;
          background: #EA0B06;
          border-radius: 50px;
        }
      }
    }
  }
}
.user-my-consult-list{
  padding: 16px 16px 0;
  >div{
    padding: 20px 16px 0;
    background: #FFFFFF;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
    >span{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      height: 24px;
      background: rgba(191,191,191,0.1500);
      border-radius: 4px;
      padding: 0 6px;
      line-height: 24px;
      font-size: 12px;
      color: #BFBFBF;
      &.sqz{
        color: #FEBE7C;
        background: rgba(254,190,124,0.1500);
      }
      &.ysl{
        color: #52AFFC;
        background: rgba(82,175,252,0.1500);
      }
      &.yhf{
        color: #37D2B3;
        background: rgba(55,210,179,0.1500);
      }
    }
    >strong{
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
      display: block;
      margin-bottom: 12px;
    }
    .user-my-consult-time-box{
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      >p{
        .user-my-consult-time-title{
          display: flex;
          line-height: 17px;
          font-size: 12px;
          color: #999999;
          align-items: center;
          margin-bottom: 2px;
          >img{
            width: 13px;
            margin-right: 6px;
          }
        }
        .user-my-consult-time{
          display: block;
          font-size: 12px;
          line-height: 18px;
          color: #333;
        }
      }
    }
    .user-my-consult-info{
      border-top: 1px solid #eee;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      >span{
        font-size: 12px;
        color: #333;
      }
      >i{
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
</style>
<style>
.empty-image .van-empty__image{
  width: 94px;
  height: 81px;
}
</style>